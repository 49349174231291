import { lazy, Suspense } from "react";

const LottieComponent = lazy(() => import("lottie-react"));

export default function Animation({
  animationData,
  loop,
}: {
  animationData: object;
  loop: boolean;
}) {
  return (
    <Suspense fallback={<></>}>
      <LottieComponent animationData={animationData} loop={loop} />
    </Suspense>
  );
}
